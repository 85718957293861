<template>
  <div class='login-log'>
    <el-table :data="tableData" swper border :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass">
      <el-table-column prop="createTime" label="时间" align="center"></el-table-column>
      <el-table-column prop="ip" label="IP" align="center"></el-table-column>
      <el-table-column prop="address" label="地区" align="center"></el-table-column>
      <el-table-column prop="browser" label="浏览器" align="center"></el-table-column>
    </el-table>
    <br />
    <pagination
      class="page tc mt10"
      :total="page.total"
      :page.sync="page.page"
      :limit.sync="page.limit"
      @pagination="getList"
    />
  </div>
</template>

<script>
import pagination from '@/components/Pagination'
import { readAdminSysLoginLog } from '@/api/sys';
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: 'login-log',
  components: {
    pagination
  },
  data () {
    return {
      page: new Page(), // 分页
      tableData: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    // 获取日志
    getList () {
      readAdminSysLoginLog().then(res => {
        this.tableData = res.data;
        this.page.total = res.total;
      })
    },
  }
}
</script>

<style lang='scss' scoped>
</style>
